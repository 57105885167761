<template>
    <mercur-card id="scroller" class="fill full-height-layout mx-4 mb-3">
        <grid-header :big-headline="true" class="mb-1">
            Pricing Rules
            <div class="row d-flex align-items-center pt-2 pl-2">
                <agreement-select data-test="agreementSelector" class="agreement-select" v-model="form.agreementId" returnField="agreementId"></agreement-select>
                <pricing-rule-template-selector
                    data-test="pricingRulesSelector"
                    v-if="form.agreementId"
                    class="template-select"
                    v-model="form.pricingRuleTemplateId"
                    :agreementId="form.agreementId"
                    returnField="pricingRuleTemplateId"
                    @fetched="setTemplates"
                />
            </div>
        </grid-header>
        <template v-if="form.agreementId && form.pricingRuleTemplateId">
            <alert type="info" class="mb-15 mt-0 u-text-center" v-if="isInProduction">
                <i class="fas fa-exclamation-circle mr-1"></i>
                This template is in production!
            </alert>
            <alert type="danger" class="mb-15 mt-0 u-text-center" v-if="needToRunPricing">
                <i class="fas fa-exclamation-triangle mr-1"></i>
                You need to rerun pricing for this template!
            </alert>
            <mercur-card data-test="pricingRulesRulesList" class="bg-primary mb-15 rounded">
                <div class="row">
                    <div class="col d-flex align-items-end">
                        <product-content-product-select
                            data-test="productsSelector"
                            class="flex-grow-1"
                            @fetched="storeProducts"
                            returnField="productConfigurationId"
                            v-model="form.products"
                            color="white"
                            :multiple="true" />
                    </div>
                    <div class="col d-flex align-items-end">
                        <country-selector
                            v-model="form.countries"
                            class="flex-grow-1"
                            color="white"
                            :agreementId="form.agreementId"
                            @fetched="storeCountries"
                        ></country-selector>
                    </div>
                    <div class="col d-flex align-items-end" style="flex: 0 0  220px;">
                        <mercur-button
                            class="btn btn-raised btn-success flex-grow-1"
                            @click="startCreate"
                            :disabled="isOpenDisabled"
                        >Open</mercur-button>
                        <mercur-button
                            data-test="filterButton"
                            class="btn btn-raised btn-white flex-grow-1"
                            @click="filterRules"
                            :disabled="isOpenDisabled"
                        >Filter</mercur-button>
                        <mercur-button
                            class="btn btn-white btn-reload flex-grow-1"
                            @click="resetFilters"
                        >
                            <i class="fa fa-rotate-right ml-1 mr-1"></i>
                        </mercur-button>
                    </div>
                </div>
            </mercur-card>
            <p class="filter-note mb-3 pl-1" v-if="Object.keys(appliedFilters).length">
                <strong>Filtered by:&nbsp;</strong>
                <span v-for="(values, key) in appliedFilters" :key="key">({{ key | beautify }}) {{ getFilterInfo(key, values) }}; </span>
                <mercur-button data-test="resetFiltersButton" class="btn btn-white btn-raised ml-3" @click="resetFilters">
                    <i class="fa fa-close mr-1"></i>
                    Reset filters
                </mercur-button>
            </p>
            <div>
                <div class="col-12 filtering-loader mb-3" v-if="loading || filtering">
                    <mercur-spinner />
                </div>

                <pricing-rule
                    class="pricing-rule--new"
                    v-if="newRule"
                    v-bind="$data"
                    :item="newRule"
                    :agreementId="form.agreementId"
                    :pricingRuleTemplateId="form.pricingRuleTemplateId"
                    :priceMargins="priceMargins"
                    :optimizedShippings="optimizedShippings"
                    :turnaroundStrategies="turnaroundStrategies"
                    :countries="countries"
                    @save="saveRule"
                    @delete="stopCreate"
                />

                <template v-if="Object.keys(appliedFilters).length">
                    <div v-for="(productTemplate) in filteredProductTemplates" :key="productTemplate">
                        <pricing-rule-group
                            :loading="isLoadingProducts"
                            :creationDisabled="isCreationDisabled"
                            :productTemplateName="productTemplateName(productTemplate)"
                            :items="pricingRules(productTemplate, true)"
                            :productTemplateId="productTemplate"
                            :agreementId="form.agreementId"
                            :pricingRuleTemplateId="form.pricingRuleTemplateId"
                            :shopHash="shopHash"
                            :priceMargins="priceMargins"
                            :optimizedShippings="optimizedShippings"
                            :turnaroundStrategies="turnaroundStrategies"
                            :countries="countries"
                            :products="products"
                            @add="addRule"
                            @copy="copyRule"
                            @update="updateRule"
                            @delete="deleteRule"
                            @sortImportance="applyItemUpdates"
                        />
                    </div>
                </template>
                <template v-else>
                    <div v-for="(productTemplate) in productTemplates" :key="productTemplate">
                        <pricing-rule-group
                            :loading="isLoadingProducts"
                            :creationDisabled="isCreationDisabled"
                            :productTemplateName="productTemplateName(productTemplate)"
                            :items="pricingRules(productTemplate)"
                            :productTemplateId="productTemplate"
                            :agreementId="form.agreementId"
                            :pricingRuleTemplateId="form.pricingRuleTemplateId"
                            :shopHash="shopHash"
                            :priceMargins="priceMargins"
                            :optimizedShippings="optimizedShippings"
                            :turnaroundStrategies="turnaroundStrategies"
                            :countries="countries"
                            :products="products"
                            @add="addRule"
                            @copy="copyRule"
                            @update="updateRule"
                            @delete="deleteRule"
                            @sortImportance="applyItemUpdates"
                            v-if="countries.length"
                        />
                    </div>
                </template>
            </div>
        </template>
    </mercur-card>
</template>

<script>
import GridHeader from '@/components/GridHeader'
import AgreementSelect from '../../components/agreements/AgreementSelect'
import ProductContentProductSelect from '../../components/product_content_management/ProductContentProductSelect'
import CountrySelector from '../../components/pricing/CountrySelector'
import PricingRule from '../../components/pricing/PricingRule'
import PricingRuleGroup from '../../components/pricing/PricingRuleGroup.vue'
import PricingRuleTemplateSelector from '../../components/pricing/PricingRuleTemplateSelector'
import PricingHelperMethods from '../../components/pricing/PricingHelperMethods'
import Alert from '../../components/Alert'
import countries from 'country-region-data'
import CONFIG from '@root/config'

const emptyRule = {
    priceMarginProductId: null,
    priceMarginCarrierId: null,
    turnaroundStrategyId: null,
    optimizedShippingId: null,
}

export default {
    name: 'PricingRules',
    mixins: [PricingHelperMethods],
    components: {
        PricingRuleGroup,
        GridHeader,
        AgreementSelect,
        CountrySelector,
        PricingRule,
        ProductContentProductSelect,
        PricingRuleTemplateSelector,
        Alert,
    },
    data () {
        return {
            form: {},
            items: [],
            products: [],
            countries: [],
            priceMargins: [],
            optimizedShippings: [],
            turnaroundStrategies: [],
            pricingRuleTemplates: [],
            shopHash: null,
            loading: false,
            templates: null,
            newRule: null,
            appliedFilters: {},
            allCountries: countries,
            filteredItems: [],
            filtering: false,
        }
    },
    watch: {
        'form.pricingRuleTemplateId': {
            handler (value) {
                this.$router.push({
                    name: 'PricingView',
                    params: {
                        ...this.$route.params,
                        templateId: value,
                    },
                })
                this.localListPricingRules()
            },
        },
        'form.agreementId': {
            handler (value) {
                if (!value) {
                    return
                }
                this.$router.push({
                    name: 'PricingView',
                    params: {
                        ...this.$route.params,
                        agreementId: value,
                    },
                })
                this.$set(this, 'items', [])
                this.localGetShops()
            },
        },
    },
    computed: {
        isOpenDisabled () {
            if (this.newRule) {
                return true
            }

            return (!this.form.products || !this.form.products.length) &&
                (!this.form.countries || !this.form.countries.length)
        },
        isCreationDisabled () {
            return !!this.newRule
        },
        isLoadingProducts () {
            return !this.products || !this.products.length
        },
        isInProduction () {
            if (!Array.isArray(this.templates) || !this.form.pricingRuleTemplateId) {
                return false
            }

            const template = this.templates.find(e => e.pricingRuleTemplateId === this.form.pricingRuleTemplateId)

            return (template && template.templateStatus) === 'PRODUCTION'
        },
        needToRunPricing () {
            if (!Array.isArray(this.templates) || !this.form.pricingRuleTemplateId) {
                return false
            }

            return this.templates.find(e => e.pricingRuleTemplateId === this.form.pricingRuleTemplateId).updated
        },
        productTemplates () {
            const productTemplates = new Set()
            this.items.forEach(pricingRule => productTemplates.add(pricingRule.productTemplateId))

            return Array.from(productTemplates).sort((a, b) => {
                return (a === null) - (b === null) || +(a > b) || -(a < b)
            })
        },
        filteredProductTemplates () {
            const productTemplates = new Set()
            this.filteredItems.forEach(pricingRule => productTemplates.add(pricingRule.productTemplateId))

            return Array.from(productTemplates).sort((a, b) => {
                return (a === null) - (b === null) || +(a > b) || -(a < b)
            })
        },
    },
    methods: {
        pricingRules (productTemplateId, filtered = false) {
            return filtered
                ? this.filteredItems.filter(e => e.productTemplateId === productTemplateId)
                : this.items.filter(e => e.productTemplateId === productTemplateId)
        },
        productTemplateName (productTemplateId) {
            const product = this.products.find(e => e.productConfigurationId === productTemplateId)
            return product ? product.productName : 'Default Pricing Rule'
        },
        addRule (productTemplateId = null, data = null) {
            this.newRule = this.buildNewRule(productTemplateId, data)
            document.getElementById('scroller').scrollTo({ top: 0, behavior: 'smooth' })
        },
        buildNewRule (productTemplateId = null, data = null) {
            if (!data) {
                return {
                    ...emptyRule,
                    agreementId: this.form.agreementId,
                    pricingRuleTemplateId: this.form.pricingRuleTemplateId,
                    countries: this.form.countries || [],
                    productTemplateId: productTemplateId,
                    attributeConfiguration: this.form.attributeConfiguration || {},
                    quantities: this.form.quantities || [],
                }
            }

            return {
                pricingRuleName: data.pricingRuleName,
                priceMarginProductId: data.priceMarginProductId,
                priceMarginCarrierId: data.priceMarginCarrierId,
                turnaroundStrategyId: data.turnaroundStrategyId,
                optimizedShippingId: data.optimizedShippingId,
                minGpm: data.minGpm,
                agreementId: this.form.agreementId,
                pricingRuleTemplateId: this.form.pricingRuleTemplateId,
                countries: [...data.countries],
                productTemplateId: productTemplateId,
                attributeConfiguration: { ...data.attributeConfiguration } || {},
                quantities: [...data.quantities],
            }
        },
        copyRule (data) {
            this.addRule(data.productTemplateId, data)
        },
        startCreate () {
            const selectedProducts = this.form.products || []

            if (selectedProducts.length > 1) {
                this.$root.$emit('notification:global', {
                    message: 'Please select only one product template',
                    type: 'error',
                })
                return
            }

            this.addRule(selectedProducts[0] || null)
        },
        stopCreate () {
            this.$set(this, 'newRule', null)
        },
        saveRule (data) {
            this.$set(this, 'items', [
                ...this.items,
                data,
            ])
            if (this.filteredItems && this.filteredItems.length) {
                this.$set(this, 'filteredItems', [
                    ...this.filteredItems,
                    data,
                ])
            }
            this.$set(this, 'newRule', null)
        },
        updateRule (id, data) {
            const index = this.items.findIndex(e => e.pricingRuleId === id)
            this.items.splice(index, 1, data)

            if (this.filteredItems && this.filteredItems.length) {
                const filteredIndex = this.filteredItems.findIndex(e => e.pricingRuleId === id)
                if (filteredIndex >= 0) {
                    this.filteredItems.splice(filteredIndex, 1, data)
                }
            }
        },
        deleteRule (id) {
            const index = this.items.findIndex(e => e.pricingRuleId === id)
            this.items.splice(index, 1)

            if (this.filteredItems && this.filteredItems.length) {
                const filteredIndex = this.filteredItems.findIndex(e => e.pricingRuleId === id)
                if (filteredIndex >= 0) {
                    this.filteredItems.splice(filteredIndex, 1)
                }
            }
        },
        storeProducts (data) {
            if (this.form.products && this.form.products.length) {
                const ids = data.map(e => e.productConfigurationId)
                this.form.products.forEach((product, index) => {
                    if (!ids.includes(product)) {
                        this.form.products.splice(index, 1)
                    }
                })
            }

            if (this.products.length) {
                return
            }
            this.products = data
        },
        storeCountries (data) {
            if (this.countries.length) {
                return
            }
            this.countries = data
        },
        filterRules () {
            const filterModel = {}
            const filters = ['products', 'countries']

            this.appliedFilters = {}
            filters.forEach((item) => {
                if (this.form[item] && this.form[item].length) {
                    this.appliedFilters[item] = this.form[item]
                    filterModel[item] = {
                        filter: this.form[item].join(','),
                        filterType: 'text',
                        type: 'equals',
                    }
                }
            })

            this.filtering = true
            this.listPricingRules(this.form.pricingRuleTemplateId, filterModel).then((data) => {
                this.$set(this, 'filteredItems', data)
            }).finally(() => {
                this.filtering = false
            })
        },
        getFilterInfo (key, values) {
            if (key === 'products') {
                const ownProducts = this.products.filter(e => values.includes(e.productConfigurationId))
                return ownProducts.map(e => e.productName).join(', ')
            }

            if (key === 'countries') {
                const ownCountries = this.allCountries.filter(e => values.includes(e.countryShortCode))
                return ownCountries.map(e => e.countryName).join(', ')
            }

            return 'error calculating...'
        },
        resetFilters () {
            this.appliedFilters = {}
            this.$delete(this.form, 'products')
            this.$delete(this.form, 'countries')
            this.$set(this, 'filteredItems', [])
            this.localListPricingRules()
        },
        // LISTS
        listPriceMargins () {
            const url = CONFIG.API.ROUTES.PRICING.MARGIN.LIST
            this.loading = true
            this.$api.post(url, CONFIG.API.REQUEST_DEFAULT).then(({ data }) => {
                this.$set(this, 'priceMargins', data.data.items)
            }).catch((err) => {
                this.$root.$emit('notification:global', {
                    message: 'Something went wrong fetching price margins',
                    type: 'error',
                    errors: err,
                })
            }).finally(() => {
                this.loading = false
            })
        },
        listOptimizedShippings () {
            const url = CONFIG.API.ROUTES.PRICING.OPTIMIZED_SHIPPING.LIST
            this.loading = true
            this.$api.post(url, CONFIG.API.REQUEST_DEFAULT).then(({ data }) => {
                // @TODO: REMOVE THIS HACKY STUFF
                const withoutTransformed = []
                data.data.items.forEach((item) => {
                    delete item.optimizedShippingTable.transformed
                    withoutTransformed.push(item)
                })
                this.$set(this, 'optimizedShippings', withoutTransformed)
            }).catch((err) => {
                this.$root.$emit('notification:global', {
                    message: 'Something went wrong fetching optimized shippings',
                    type: 'error',
                    errors: err,
                })
            }).finally(() => {
                this.loading = false
            })
        },
        listTurnaroundStrategies () {
            const url = CONFIG.API.ROUTES.PRICING.TURNAROUND_STRATEGY.LIST
            this.loading = true
            this.$api.post(url, CONFIG.API.REQUEST_DEFAULT).then(({ data }) => {
                this.$set(this, 'turnaroundStrategies', data.data.items)
            }).catch((err) => {
                this.$root.$emit('notification:global', {
                    message: 'Something went wrong fetching turnaround strategies',
                    type: 'error',
                    errors: err,
                })
            }).finally(() => {
                this.loading = false
            })
        },
        localListPricingRules () {
            this.loading = true
            this.listPricingRules(this.form.pricingRuleTemplateId).then((data) => {
                this.$set(this, 'items', data)
            }).finally(() => {
                this.loading = false
            })
        },
        localGetShops () {
            this.getShops(this.form.agreementId).then((data) => {
                this.shopHash = data.data.items[0].shopHash
            })
        },
        applyItemUpdates (data) {
            data.forEach((pricingRule) => {
                const index = this.items.findIndex(e => e.pricingRuleId === pricingRule.pricingRuleId)
                if (index >= 0) {
                    this.items.splice(index, 1, pricingRule)
                }

                if (this.filteredItems && this.filteredItems.length) {
                    const filteredIndex = this.filteredItems.findIndex(e => e.pricingRuleId === pricingRule.pricingRuleId)
                    if (filteredIndex >= 0) {
                        this.filteredItems.splice(filteredIndex, 1, pricingRule)
                    }
                }
            })
        },
        setTemplates (data) {
            this.$set(this, 'templates', data)
        },
    },
    created () {
        this.listPriceMargins()
        this.listOptimizedShippings()
        this.listTurnaroundStrategies()

        if (this.$route.params.agreementId) {
            this.$set(this.form, 'agreementId', this.$route.params.agreementId)
        }

        if (this.$route.params.templateId) {
            this.$set(this.form, 'pricingRuleTemplateId', this.$route.params.templateId)
        }
    },
}
</script>

<style lang="scss" scoped>
    .agreement-select {
        display: inline-block;
        width: 300px;
        margin-left: 10px;
        vertical-align: middle;
    }
    .template-select {
        display: inline-block;
        width: 300px;
        margin-left: 10px;
        vertical-align: middle;
    }
    .pricing-rule--new {
        -webkit-box-shadow: 0px 0px 5px 5px #cce5ff;
        -moz-box-shadow: 0px 0px 5px 5px #cce5ff;
        box-shadow: 0px 0px 5px 5px #cce5ff;
        margin-bottom: 15px!important;
        border-bottom: 0!important;
    }
    .btn-top {
        height: 46px !important;
        margin: 4px 0 0 !important;
    }
    .btn-reload {
        min-width: auto;
    }
    .filter-note {
        margin: 0;

        button {
            height: 32px;
        }
    }
    .filtering-loader {
        z-index: 2;
        background-color: rgba(255,255,255,0.5);
        display: flex;
        justify-content: center;

        p {
            text-align: center;
            background-color: white;
            max-height: 40px;
            margin-top: 32px;
            padding: 10px 25px;
            border-radius: 2px;
        }
    }
</style>
