<template>
    <mercur-card class="pricing-rule mb-3 p-0">
        <div class="pricing-rule__head rounded-top" :class="{'rounded': !this.isExpanded}">
            <div class="row">
                <div class="col-8">
                    <mercur-spinner size="1" class="mt-2" v-if="loading" />
                    <p v-else class="pricing-rule__title">{{ productTemplateName || 'Default Pricing Rule' }}</p>
                </div>
                <div class="col-4 text-right pricing-rule__actions">
                    <mercur-button class="btn btn-white" v-if="hasMultipleRules()" @click="toggleImportanceSorter()">
                        Change importance
                    </mercur-button>
                    <mercur-button class="btn btn-white btn-expand" v-if="hasProductTemplate()" :disabled="creationDisabled" @click="addRule()">
                        <i class="fas fa-plus ml-1 mr-1"></i>
                    </mercur-button>
                    <mercur-button class="btn btn-white btn-expand" @click="toggleExpanded()">
                        <i class="fas fa-chevron-down ml-1 mr-1" v-if="!isExpanded"></i>
                        <i class="fas fa-chevron-up ml-1 mr-1" v-else></i>
                    </mercur-button>
                </div>
            </div>
        </div>

        <div v-show="isExpanded" class="mb-3">
            <pricing-rule
                v-for="(item) in items"
                v-bind="$data"
                :item="item"
                :importance="item.importance"
                :agreementId="agreementId"
                :pricingRuleTemplateId="pricingRuleTemplateId"
                :shopHash="shopHash"
                :priceMargins="priceMargins"
                :optimizedShippings="optimizedShippings"
                :turnaroundStrategies="turnaroundStrategies"
                :countries="countries"
                :products="products"
                @copy="copyRule"
                @update="updateRule"
                @delete="deleteRule"
                :key="item.pricingRuleId"
                :copyDisabled="creationDisabled"
            />
        </div>

        <importance-sorter
            v-if="hasMultipleRules()"
            :items="items"
            :priceMargins="priceMargins"
            @updated="resetImportanceSort"
            v-model="isImportanceOpen"
        />
    </mercur-card>
</template>

<script>
import PricingRule from './PricingRule'
import ImportanceSorter from '@/components/pricing/ImportanceSorter'

export default {
    name: 'PricingRuleGroup',
    components: { ImportanceSorter, PricingRule },
    props: {
        items: {
            required: true,
        },
        loading: {
            default: false,
        },
        creationDisabled: {
            default: false,
        },
        productTemplateId: {
            default: null,
        },
        productTemplateName: {
            required: true,
        },
        agreementId: {
            required: true,
        },
        pricingRuleTemplateId: {
            required: true,
        },
        shopHash: {
            required: true,
        },
        priceMargins: {
            required: true,
        },
        optimizedShippings: {
            required: true,
        },
        turnaroundStrategies: {
            required: true,
        },
        countries: {
            required: true,
        },
        products: {
            required: true,
        },
    },
    data () {
        return {
            isExpanded: false,
            isImportanceOpen: false,
        }
    },
    methods: {
        toggleExpanded () {
            this.isExpanded = !this.isExpanded
        },
        toggleImportanceSorter () {
            this.isImportanceOpen = !this.isImportanceOpen
        },
        hasMultipleRules () {
            return this.items.length > 1
        },
        hasProductTemplate () {
            return this.productTemplateId !== null
        },
        addRule () {
            this.$emit('add', this.productTemplateId)
        },
        copyRule (data) {
            this.$emit('copy', data)
        },
        updateRule (id, data) {
            this.$emit('update', id, data)
        },
        deleteRule (id) {
            this.$emit('delete', id)
        },
        resetImportanceSort (data) {
            this.$emit('sortImportance', data)
        },
    },
}
</script>

<style lang="scss" scoped>
    @import '../../assets/scss/_variables.scss';
    .pricing-rule {
        &__head {
            background-color: $dark-color;
            padding: 6px 15px;
        }

        &__title {
            color: white;
            font-weight: 500;
            font-size: 16px;
            line-height: 32px;
            margin: 0;

            i {
                font-size: 18px !important;
                margin-left: 5px;

                cursor: pointer;
            }
        }

        &__actions {
            align-items: center;
            justify-content: flex-end;
            display: flex;
        }

        &__info {
            font-size: 11px;
            color: #cccccc;
            margin-left: 25px;
        }

        button {
            height: 32px;
            line-height: normal;
        }
    }

    .btn-expand {
        min-width: auto;
    }
</style>
