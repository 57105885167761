<template>
    <div>
        <pretty-select
            v-if="items"
            placeholder="Select template"
            label="templateName"
            :options="items"
            v-model="selectedTemplate"
            :required="required"
            :reduce="reducedPayload"
            :multiple="false"
            data-test="pricingRuleTemplateSelector"
        ></pretty-select>
    </div>
</template>

<script>
import PrettySelect from '../../components/utils/PrettySelect'
import CONFIG from '@root/config'

export default {
    name: 'PricingRuleTemplateSelector',
    components: { PrettySelect },
    data () {
        return {
            items: null,
        }
    },
    props: {
        required: {
            default: false,
        },
        returnField: {
            default: null,
        },
        template: {
            default: null,
        },
        agreementId: {
            default: null,
        },
    },
    model: {
        prop: 'template',
        event: 'templateChange',
    },
    computed: {
        selectedTemplate: {
            get () {
                return this.template
            },
            set (value) {
                this.$emit('templateChange', value)
            },
        },
        reducedPayload () {
            if (this.returnField) {
                return (e) => e[this.returnField]
            }

            return e => e
        },
    },
    watch: {
        agreementId () {
            this.fetchData()
        },
    },
    methods: {
        fetchData () {
            const url = CONFIG.API.ROUTES.PRICING.PRICING_RULE_TEMPLATES.LIST.replace('{agreementId}', this.agreementId)
            const requestPayload = JSON.parse(JSON.stringify(CONFIG.API.REQUEST_DEFAULT))
            if (!this.isAllowedTo('SupplierCentral/listProductCategories')) {
                return
            }

            this.addJob(url)
            this.$api.post(url, requestPayload).then(({ data }) => {
                this.items = data.data.items
                this.$emit('fetched', this.items)
            }).catch(err => {
                this.$root.$emit('notification:global', {
                    message: err,
                    type: 'error',
                })
            }).finally(() => {
                this.finishJob(url)
            })
        },
    },
    created () {
        this.fetchData()
    },
}
</script>
