<template>
    <div data-test="importanceSorterWrapper">
        <mercur-dialog data-test="importanceSorterDialog" width="800px" :is-open.sync="localValue">
            <h3 class="mt-1">Change importance for pricing rules</h3>
            <mercur-card class="mb-2" v-for="item in sortedItems" :key="item.pricingRuleId">
                <div class="d-flex justify-content-between align-items-center">
                    <span>{{ item.pricingRuleName }}</span>
                    <mercur-select class="m-0" @selected="resort(item, $event)" :value="item.importance">
                        <option :value="option - 1" v-for="option in items.length" :key="option">{{ option - 1 }}</option>
                    </mercur-select>
                </div>
            </mercur-card>

            <div class="text-right mt-3">
                <mercur-button data-test="importanceSorterBtnClose" class="btn" @click="triggerClose" :disabled="loading">Cancel</mercur-button>
                <mercur-button data-test="importanceSorterBtnSave" class="btn btn-raised btn-primary" @click="updateImportance" :disabled="loading">Save changes</mercur-button>
            </div>
        </mercur-dialog>
    </div>
</template>

<script>
import CONFIG from '@root/config'

export default {
    name: 'ImportanceSorter',
    props: {
        items: {
            required: true,
            default: [],
        },
        value: {
            required: true,
        },
        priceMargins: {
            required: true,
        },
    },
    data () {
        return {
            localItems: JSON.parse(JSON.stringify(this.items)),
            loading: false,
        }
    },
    watch: {
        items (value) {
            this.$set(this, 'localItems', JSON.parse(JSON.stringify(value)))
        },
    },
    computed: {
        sortedItems () {
            return [...this.localItems].sort((a, b) => {
                return a.importance - b.importance
            })
        },
        localValue: {
            get () {
                return this.value
            },
            set (value) {
                this.$emit('input', value)
            },
        },
    },
    methods: {
        resort (changedItem, newValue) {
            const oldValue = changedItem.importance
            const index = this.localItems.findIndex(e => e.pricingRuleId === changedItem.pricingRuleId)
            this.$set(this.localItems[index], 'importance', newValue)
            this.sortedItems.forEach((item, index) => {
                if (item.pricingRuleId === changedItem.pricingRuleId) {
                    return
                }

                if (index === parseInt(newValue)) {
                    if (oldValue > newValue) {
                        item.importance = index + 1
                    } else {
                        item.importance = index - 1
                    }
                } else {
                    item.importance = index
                }
            })
        },
        triggerClose () {
            this.localItems = JSON.parse(JSON.stringify(this.items))
            this.localValue = false
        },
        updateImportance () {
            const url = CONFIG.API.ROUTES.PRICING.PRICING_RULES.UPDATE_IMPORTANCE
            this.loading = true
            const payload = {}
            this.sortedItems.forEach(item => {
                payload[item.pricingRuleId] = parseInt(item.importance)
            })

            this.$api.post(url, { importance: payload }).then(({ data }) => {
                this.$root.$emit('notification:global', {
                    message: 'Importance for pricing rules was updated',
                    type: 'success',
                })
                this.$emit('updated', this.sortedItems)
                this.triggerClose()
            }).catch((err) => {
                this.$root.$emit('notification:global', {
                    message: 'Something went wrong',
                    type: 'error',
                    errors: err,
                })
            }).finally(() => {
                this.loading = false
            })
        },
    },
}
</script>
