<template>
    <div>
        <mercur-spinner data-test="productContentSelectorSpinner" v-if="!items"/>
        <pretty-select
            data-test="productContentSelector"
            v-if="items"
            placeholder="Select products"
            label="productName"
            :options="items"
            v-model="selectedProduct"
            :required="required"
            :reduce="reducedPayload"
            :multiple="multiple"
            :disabled="disabled"
            :color="color"
        ></pretty-select>
    </div>
</template>

<script>
import PrettySelect from '../../components/utils/PrettySelect'
import CONFIG from '@root/config'

export default {
    name: 'ProductContentProductSelect',
    components: { PrettySelect },
    data () {
        return {
            items: null,
        }
    },
    props: {
        required: {
            default: false,
        },
        returnField: {
            default: null,
        },
        multiple: {
            default: null,
        },
        product: {
            default: null,
        },
        disabled: {
            default: false,
        },
        categories: {
            default: () => [],
        },
        color: {
            default: null,
        },
    },
    model: {
        prop: 'product',
        event: 'productChange',
    },
    watch: {
        categories: {
            handler () {
                this.getProducts()
            },
        },
    },
    computed: {
        selectedProduct: {
            get () {
                if (!this.product && this.multiple) {
                    return null
                }
                return this.product
            },
            set (value) {
                this.$emit('productChange', value)
            },
        },
        reducedPayload () {
            if (this.returnField) {
                return (e) => e[this.returnField]
            }

            return e => e
        },
    },
    methods: {
        getProducts () {
            if (!this.isAllowedTo('SupplierCentral/listMarketplaceProducts')) {
                return
            }
            const url = CONFIG.API.ROUTES.PRODUCT_CONTENT.PRODUCTS.LIST
            const requestPayload = JSON.parse(JSON.stringify(CONFIG.API.REQUEST_DEFAULT))

            if (this.categories.length) {
                requestPayload.categories = this.categories
            }

            this.addJob(url)
            this.items = null
            this.$api.post(url, requestPayload).then(({ data }) => {
                this.items = data.data.items
                this.$emit('fetched', this.items)
            }).catch(err => {
                this.$root.$emit('notification:global', {
                    message: err,
                    type: 'error',
                })
            }).finally(() => {
                this.finishJob(url)
            })
        },
    },
    created () {
        this.getProducts()
    },
}
</script>
