<template>
    <mercur-card class="pricing-rule rounded-bottom mb-0 p-0">
        <div class="p-3">
            <div class="row" v-if="Number.isInteger(importance)">
                <div class="col-12 mb-3"><b>Importance rank: {{ importance }}</b></div>
            </div>
            <div class="row">
                <div class="col-12">
                    <mercur-input
                        v-model.trim="localValue.pricingRuleName"
                        :disabled="loading || isDisabled"
                        :class="{'form-invalid': $v.localValue.pricingRuleName.$error}"
                    >
                        Pricing rule name
                        <template slot="note">
                            <span class="form-error" v-if="!$v.localValue.pricingRuleName.required">Required</span>
                        </template>
                    </mercur-input>
                </div>
                <div class="col-6 form-field" :class="{'form-invalid': $v.localValue.priceMarginProductId.$error}">
                    <pretty-select
                        v-model="localValue.priceMarginProductId"
                        :disabled="loading || isDisabled"
                        :options="priceMargins"
                        placeholder="Product Price Margin"
                        customPlaceholder="Select an option"
                        label="priceMarginName"
                        :get-option-key="option => option.priceMarginId"
                        :get-option-label="option => `${type(option.priceMarginType)} # ${option.priceMarginName}`"
                        :reduce="option => option.priceMarginId"
                        :always-show-placeholder="true"
                        :prevent-text-wrap="true"
                    >
                    </pretty-select>
                    <span class="form-note">
                        <span class="form-error" v-if="!$v.localValue.priceMarginProductId.required">Required</span>
                    </span>
                </div>
                <div class="col-6 form-field" :class="{'form-invalid': $v.localValue.priceMarginCarrierId.$error}">
                    <pretty-select
                        v-model="localValue.priceMarginCarrierId"
                        :disabled="loading || isDisabled"
                        :options="priceMargins"
                        placeholder="Carrier Price Margin"
                        customPlaceholder="Select an option"
                        label="priceMarginName"
                        :get-option-key="option => option.priceMarginId"
                        :get-option-label="option => `${type(option.priceMarginType)} # ${option.priceMarginName}`"
                        :reduce="option => option.priceMarginId"
                        :always-show-placeholder="true"
                        :prevent-text-wrap="true"
                    >
                    </pretty-select>
                    <span class="form-note">
                        <span class="form-error" v-if="!$v.localValue.priceMarginCarrierId.required">Required</span>
                    </span>
                </div>
                <div class="col-3 form-field" :class="{'form-invalid': $v.localValue.turnaroundStrategyId.$error}">
                    <pretty-select
                        v-model="localValue.turnaroundStrategyId"
                        :disabled="loading || isDisabled"
                        :options="turnaroundStrategies"
                        placeholder="Turnaround Strategy"
                        customPlaceholder="Select an option"
                        label="turnaroundStrategyName"
                        :get-option-key="option => option.turnaroundStrategyId"
                        :reduce="option => option.turnaroundStrategyId"
                        :always-show-placeholder="true"
                        :prevent-text-wrap="true"
                    >
                    </pretty-select>
                    <span class="form-note">
                        <span class="form-error" v-if="!$v.localValue.turnaroundStrategyId.required">Required</span>
                    </span>
                </div>
                <div class="col-3">
                    <mercur-input
                        v-model.trim="localValue.minGpm"
                        :disabled="loading || isDisabled"
                        :class="{'form-invalid': $v.localValue.minGpm.$error}"
                    >
                        Min GPM
                        <template slot="note">
                            <span class="form-error" v-if="!$v.localValue.minGpm.decimal">Min GPM is decimal</span>
                            <span class="form-error" v-if="!$v.localValue.minGpm.between">Min GPM must be between {{$v.localValue.minGpm.$params.between.min}} and {{$v.localValue.minGpm.$params.between.max}}</span>
                        </template>
                    </mercur-input>
                </div>
                <div class="col-6 inline-checkbox" :class="{'form-invalid': displayUnlockPricesNote}">
                    <mercur-checkbox v-model="localValue.lockPrices" :disabled="loading || isDisabled">
                        Lock sales prices for this pricing rule
                    </mercur-checkbox>
                    <span class="form-note">
                        <span class="form-error" v-if="displayUnlockPricesNote">The pricing generation must be triggered for this to have an effect</span>
                    </span>
                </div>
                <div class="col-12">
                    <country-selector
                        v-model="localValue.countries"
                        :disabled="loading || isDisabled"
                        :agreementId="agreementId"
                        :initialCountries="countries"
                    ></country-selector>
                </div>
            </div>
            <div v-if="localValue.productTemplateId !== null" class="row">
                <div class="col-12 mt-3">
                    <pricing-attribute-configuration
                        v-if="computedHashFromList"
                        v-model="localValue.attributeConfiguration"
                        ref="attributeConfigurationAndQuantities"
                        :shop-hash="shopHash"
                        :product-template-id="localValue.productTemplateId"
                        :local-quantities.sync="localValue.quantities"
                        @updateQuantities="updateQuantities"
                        :product-hash="computedHashFromList"
                    />
                    <span v-else>Product hash is missing</span>
                </div>
            </div>
        </div>
        <div class="p-3">
            <div class="row">
                <div class="col-12">
                    <div class="u-float-left">
                        <mercur-button class="btn btn-danger btn-raised" @click="triggerDelete" :disabled="loading || isDisabled">
                            <i class="fas fa-trash"></i>
                            Delete rule
                        </mercur-button>
                    </div>
                    <div class="u-float-right">
                        <mercur-button class="btn btn-white" @click="copy" v-if="canCopy()" :disabled="loading || isDisabled || copyDisabled">
                            <i class="fas fa-copy"></i>
                            Copy rule
                        </mercur-button>
                        <mercur-button class="btn btn-white" @click="undo" :disabled="loading || isDisabled">Cancel changes</mercur-button>
                        <mercur-button class="btn btn-success btn-raised" @click="save" :disabled="loading || isDisabled">Save changes</mercur-button>
                    </div>
                </div>
            </div>
        </div>

        <mercur-dialog :is-open.sync="isDelete">
            <p>Are you sure you want to remove this pricing rule?</p>
            <template slot="footer">
                <mercur-button class="btn" :disabled="loading" @click="triggerClose">Cancel</mercur-button>
                <mercur-button class="btn btn-raised btn-danger" :disabled="loading" @click="remove">Delete pricing rule</mercur-button>
            </template>
        </mercur-dialog>
    </mercur-card>
</template>

<script>
import CONFIG from '@root/config'
import { required, decimal, between } from 'vuelidate/lib/validators'
import PricingAttributeConfiguration from './PricingAttributeConfiguration'
import CountrySelector from './CountrySelector'
import PrettySelect from '@/components/utils/PrettySelect'
import PricingHelperMethods from '../../components/pricing/PricingHelperMethods'

export default {
    name: 'PricingRule',
    mixins: [PricingHelperMethods],
    components: { PrettySelect, CountrySelector, PricingAttributeConfiguration },
    props: {
        item: {
            required: true,
        },
        copyDisabled: {
            default: false,
        },
        priceMargins: {
            default: () => [],
        },
        optimizedShippings: {
            default: () => [],
        },
        turnaroundStrategies: {
            default: () => [],
        },
        countries: {
            default: () => [],
        },
        shopHash: {
            default: null,
        },
        productTemplateId: {
            default: null,
        },
        pricingRuleTemplateId: {
            default: null,
        },
        agreementId: {
            default: null,
        },
        products: {
            default: () => [],
        },
        isDisabled: {
            default: false,
        },
        importance: {},
    },
    data () {
        return {
            localValue: JSON.parse(JSON.stringify(this.item)),
            draft: JSON.parse(JSON.stringify(this.item)),
            loading: false,
            isDelete: false,
            displayUnlockPricesNote: false,
        }
    },
    validations: {
        localValue: {
            pricingRuleName: { required },
            priceMarginProductId: { required },
            priceMarginCarrierId: { required },
            turnaroundStrategyId: { required },
            minGpm: { decimal, between: between(0, 99) },
        },
    },
    computed: {
        computedHashFromList () {
            const product = this.products.find(e => e.productConfigurationId === this.localValue.productTemplateId)
            if (!product) {
                return null
            }

            return product.productHash
        },
    },
    watch: {
        'localValue.attributeConfiguration': {
            handler (value) {
                if (value && this.$refs.attributeConfigurationAndQuantities) {
                    this.$refs.attributeConfigurationAndQuantities.getQuantities()
                }
            },
            deep: true,
        },
        'localValue.lockPrices': {
            handler (value) {
                this.displayUnlockPricesNote = value === false && this.draft.lockPrices === true
            },
            deep: true,
        },
    },
    methods: {
        type (value) {
            return CONFIG.TRANSLATIONS[value]
        },
        save () {
            this.$v.$touch()
            if (this.$v.$invalid) {
                this.$root.$emit('notification:global', {
                    message: 'Form is not valid',
                    type: 'error',
                })
                return
            }
            let url = CONFIG.API.ROUTES.PRICING.PRICING_RULES.CREATE
            if (this.localValue.pricingRuleId) {
                url = CONFIG.API.ROUTES.PRICING.PRICING_RULES.UPDATE
            }

            this.loading = true
            let payload = this.localValue

            this.$api.post(url, payload).then(({ data }) => {
                const pricingRule = this.convertMinGpm(data.data)

                if (!this.localValue.pricingRuleId) {
                    this.$emit('save', pricingRule)
                    this.$set(this, 'localValue', pricingRule)
                } else {
                    this.$emit('update', payload.pricingRuleId, pricingRule)
                }

                this.$set(this, 'draft', pricingRule)
                this.$root.$emit('notification:global', {
                    message: payload.pricingRuleId ? 'Pricing rule was updated' : 'Price rule was created',
                    type: 'success',
                })
            }).catch((err) => {
                this.$root.$emit('notification:global', {
                    message: 'Something went wrong',
                    type: 'error',
                    errors: err,
                })
            }).finally(() => {
                this.loading = false
            })
        },
        undo () {
            this.$set(this, 'localValue', JSON.parse(JSON.stringify(this.draft)))
            this.$root.$emit('notification:global', {
                message: 'Changes were reverted',
            })
        },
        copy () {
            this.$emit('copy', this.localValue)
        },
        updateQuantities (quantities) {
            this.$set(this.localValue, 'quantities', quantities)
        },
        triggerDelete () {
            this.isDelete = true
        },
        triggerClose () {
            this.isDelete = false
        },
        remove () {
            if (!this.localValue.pricingRuleId) {
                this.$emit('delete')
                return
            }
            let url = CONFIG.API.ROUTES.PRICING.PRICING_RULES.DELETE.replace('{pricingRuleId}', this.localValue.pricingRuleId)
            this.loading = true
            this.$api.delete(url).then(({ data }) => {
                this.$root.$emit('notification:global', {
                    message: 'Pricing rule was deleted',
                    type: 'success',
                })
                this.$emit('delete', this.localValue.pricingRuleId)
                this.triggerClose()
            }).catch((err) => {
                this.$root.$emit('notification:global', {
                    message: (err.data && err.data.exceptions && err.data.exceptions[0]) ? err.data.exceptions[0] : 'Something went wrong',
                    type: 'error',
                    errors: err,
                })
            }).finally(() => {
                this.loading = false
            })
        },
        canCopy () {
            return this.localValue.pricingRuleId && this.localValue.productTemplateId
        },
    },
}
</script>

<style lang="scss" scoped>
    @import '../../assets/scss/_variables.scss';
    .pricing-rule {
        border-bottom: 1px solid $dark-color;

        &__head {
            background-color: $dark-color;
            padding: 8px 16px;
            border-top-left-radius: 0.25rem;
            border-top-right-radius: 0.25rem;
        }

        &__title {
            color: white;
            font-weight: 500;
            font-size: 16px;
            line-height: 34px;
            margin: 0;

            i {
                font-size: 18px !important;
                margin-left: 5px;

                cursor: pointer;
            }
        }

        &__actions {
            align-items: center;
            justify-content: flex-end;
            display: flex;
        }

        &__info {
            font-size: 11px;
            color: #cccccc;
            margin-left: 25px;
        }
    }
    .radio-invalid {
        p.form-error {
            font-size: 12px;
            color: #ff1744;
            display: block;
        }
    }
    .inline-checkbox {
        line-height: 40px;
        margin-top: 15px;

        .form-error {
            line-height: 20px;
        }
    }
</style>
