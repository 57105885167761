<template>
    <div>
        <p class="title">
            <span>
                <mercur-button class="btn btn-primary btn-attributes" @click="isOpen = !isOpen" :disabled="!shopHash">
                    Attributes and options
                    <i class="fas fa-chevron-down" v-if="!isOpen"></i>
                    <i class="fas fa-chevron-up" v-else></i>
                </mercur-button>
                <span v-if="!shopHash">Shop hash is missing</span>
            </span>
        </p>
        <p class="mt-2 mb-0 text-grey" v-if="localValue && Object.keys(localValue).length">
            (<span v-for="(options, attribute) in localValue" :key="attribute">
                <strong>{{ attribute }}: </strong> {{ options.join(', ') }};
            </span>)
        </p>
        <mercur-spinner v-if="isOpen && !product" class="mt-3" />
        <div v-if="isOpen && product" class="mt-3">
            <div v-for="(values, key) in product.remainingAttributes" :key="key">
                <p class="attribute mt-2"><strong>{{ key | beautify }}</strong></p>
                <div>
                    <mercur-chip
                        class="mb-1"
                        :clickable="true"
                        :class="{'bg-primary': localValue[key] && localValue[key].includes(value)}"
                        v-for="value in values"
                        :key="value"
                        @click="updateValues(key, value)"
                    >{{ value | beautify }}</mercur-chip>
                </div>
            </div>
            <div v-for="(item, key) in product.preselectedAttributes" :key="key">
                <p class="attribute"><strong>{{ item.attributeName | beautify }}</strong></p>
                <div>
                    <mercur-chip class="mb-1 bg-primary">{{ item.attributeOption | beautify }}</mercur-chip>
                </div>
            </div>
            <div>
                <p class="attribute"><strong>Quantities</strong></p>
                <mercur-spinner v-if="loadingQuantities" />
                <div v-else-if="!loadingQuantities && !quantities.length">There are no quantities.</div>
                <div v-else>
                    <mercur-chip class="mb-1" :clickable="true" :class="{'bg-primary': localQuantities.includes(quantity)}" @click="updateQuantities(quantity)" v-for="(quantity) in quantities" :key="quantity">
                        {{ quantity }}
                    </mercur-chip>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import CONFIG from '@root/config'

export default {
    name: 'PricingAttributeConfiguration',
    props: {
        value: {},
        shopHash: {
            required: true,
            default: null,
        },
        productHash: {
            required: true,
            default: null,
        },
        productTemplateId: {
            required: true,
        },
        localQuantities: {
            default: [],
        },
    },
    data () {
        return {
            isOpen: false,
            product: null,
            quantities: [],
            loadingQuantities: false,
        }
    },
    watch: {
        isOpen (value) {
            if (value) {
                this.getAttributesAndOptions()
            }
        },
    },
    computed: {
        localValue: {
            get () {
                return this.value
            },
            set (value) {
                this.$emit('input', value)
            },
        },
    },
    methods: {
        updateValues (key, value) {
            let localClone = JSON.parse(JSON.stringify(this.localValue))
            if (Array.isArray(this.localValue)) {
                localClone = {}
            }

            if (!localClone[key]) {
                localClone[key] = []
            }

            if (localClone[key].includes(value)) {
                const index = localClone[key].findIndex(e => e === value)
                localClone[key].splice(index, 1)

                if (!localClone[key].length) {
                    delete localClone[key]
                }
                this.$set(this, 'localValue', localClone)
                return
            }

            localClone[key].push(value)
            this.$set(this, 'localValue', localClone)
        },
        updateQuantities (quantity) {
            const data = JSON.parse(JSON.stringify(this.localQuantities))
            if (this.localQuantities.includes(quantity)) {
                const index = this.localQuantities.findIndex(e => e === quantity)
                data.splice(index, 1)
                this.$emit('updateQuantities', data)
                return
            }

            data.push(quantity)
            this.$emit('updateQuantities', data)
        },
        getAttributesAndOptions () {
            const url = CONFIG.API.ROUTES.AGREEMENTS.GET_ATTRIBUTES_FOR_FUNNEL
                .replace('{shopHash}', this.shopHash)
                .replace('{productHash}', this.productHash)

            if (!this.isAllowedTo('SupplierCentral/getAttributesForFunnel')) {
                return
            }

            const payload = {}

            this.addJob(url)
            this.$api.post(url, payload).then(({ data }) => {
                this.product = data.data
                this.getQuantities()
            }).catch(err => {
                this.$root.$emit('notification:global', {
                    message: err,
                    type: 'error',
                })
            }).finally(() => {
                this.finishJob(url)
            })
        },
        getQuantities () {
            const url = CONFIG.API.ROUTES.AGREEMENTS.GET_QUANTITES_FOR_PRICING
                .replace('{productTemplateId}', this.productTemplateId)
                .replace('{productHash}', this.productHash)

            if (!this.isAllowedTo('SupplierCentral/getQuantitiesForPricingRule')) {
                return
            }

            const preselected = {}

            if (this.product && this.product.preselectedAttributes !== undefined) {
                this.product.preselectedAttributes.forEach((item) => {
                    preselected[item.attributeName] = [item.attributeOption]
                })
            }

            const payload = { ...preselected, ...this.localValue }

            this.loadingQuantities = true
            this.addJob(url)
            this.$api.post(url, payload).then(({ data }) => {
                if (data.data.length) {
                    this.$set(this, 'quantities', data.data.map(e => e.quantities))
                }
            }).catch(err => {
                this.$root.$emit('notification:global', {
                    message: err,
                    type: 'error',
                })
            }).finally(() => {
                this.finishJob(url)
                this.loadingQuantities = false
            })
        },
    },
}
</script>

<style lang="scss" scoped>
    .title {
        margin: 0;

        button {
            min-width: auto;

            i {
                margin-left: 10px;
            }
        }
    }

    .attribute {
        margin-bottom: 10px;
        border-top: 1px solid #DCDFE2;
        padding-top: 10px;
    }

    /deep/ .chip {
        height: 24px;
    }

</style>
